export interface IRegistrationForm {
  Clients: IClientRegistration[];
  Default: IFormItem[];
}

export interface IClientRegistration {
  ClientId: number;
  ClientIds?: number[];
  UseCheckboxWithDisclaimer?: boolean;
  Questions: IQuestions[];
  Existing: IFormItem[];
}

export interface IQuestions {
  Question: string;
  Type: QuestionType;
  Options: string[];
  Required: boolean;
  Name: string;
  DefaultValue: string;
  Classnames?: string[];
}

export interface IFormItem {
  Name: string;
  Required: boolean;
}

export enum QuestionType {
  Text = 'text',
  Select = 'select',
  Checkbox = 'checkbox'
}
