<nav id="quick-nav" [class.is-standalone]="isStandalone" [class.hide]="!isShowing" [class.show-back-button]="!!pageTitleToGoBackTo"
     [class.full-width]="usePermanentBottomNav">
  <div class="main-container">
    <div class="logo-container">
      <button id="back-button" (click)="handleGoBack()" [matTooltip]="backButtonTooltip">
        <icon name="back_arrow"></icon>
      </button>
      <div class="logo" [style.background-image]="logoUrl | safeBackgroundImageUrl" (click)="goHome()">
        <span *ngIf="!logoUrl">Home</span>
      </div>
    </div>
    <div *ngIf="primaryModules.length" id="primary-modules" #scrollingContainer [class.top-border-radius]="!secondaryModules.length">
      <div class="centering-container">
        <button *ngFor="let module of primaryModules; trackBy: trackById" (click)="handleModuleNavigation(module)" [ngSwitch]="module.UniqueName"
                [class.selected]="module.IsSelected" [class.expanded-brochure-button]="module.UniqueName === brochureUniqueName && !!totalFavorites"
                [matTooltip]="usePermanentBottomNav ? '' : module.Tooltip">
          <favorites-counter *ngSwitchCase="brochureUniqueName" [count]="totalFavorites"></favorites-counter>
          <ng-container *ngIf="!usePermanentBottomNav">
            <div class="icon-center" [class.with-short-name]="showShortName">
              <icon *ngSwitchDefault [name]="module.IconName" [svg]="module.IconSvg"></icon>
              <div class="short-name" *ngIf="showShortName && module.UniqueName !== brochureUniqueName">
                {{module.ShortName}}
              </div>
            </div>
          </ng-container>
          <ng-container *ngIf="usePermanentBottomNav">
            <h3>{{module.Title}}</h3>
          </ng-container>
        </button>
      </div>
    </div>
    <div id="secondary-modules" *ngIf="secondaryModules.length && !usePermanentBottomNav" [class.expanded]="isExpanded">
      <button *ngFor="let module of secondaryModules; trackBy: trackById" (click)="handleModuleNavigation(module)" [ngSwitch]="module.UniqueName"
              [class.selected]="module.IsSelected" [class.expanded-brochure-button]="module.UniqueName === brochureUniqueName && !!totalFavorites"
              [matTooltip]="module.Tooltip">
        <favorites-counter *ngSwitchCase="brochureUniqueName" [count]="totalFavorites"></favorites-counter>
        <div class="icon-center" [class.with-short-name]="showShortName">
          <icon *ngSwitchDefault [name]="module.IconName" [svg]="module.IconSvg"></icon>
          <div class="short-name" *ngIf="showShortName && module.UniqueName !== brochureUniqueName">
            {{module.ShortName}}
          </div>
        </div>
      </button>
      <button class="expander" [class.expanded]="isExpanded" (click)="handleExpandToggle()">
        <icon name="sub_menu_slide"></icon>
      </button>
    </div>
    <div id="nav-tools" *ngIf="usePermanentBottomNav">
      <icon-button *ngIf="showScrollArrows" class="arrow left" [class.hide]="!canScrollLeft" icon="carrot_arrow"
                   (click)="handleScrollStep(-1)"></icon-button>
      <icon-button *ngIf="showScrollArrows" class="arrow right" [class.hide]="!canScrollRight" icon="carrot_arrow"
                   (click)="handleScrollStep(1)"></icon-button>

      <icon-button *ngIf="pinModule" [icon]="pinModule.IconName" (click)="handleModuleNavigation(pinModule)" [matTooltip]="pinModule.Tooltip">
      </icon-button>
      <icon-button *ngIf="registrationModule" [icon]="registrationModule.IconName" (click)="handleModuleNavigation(registrationModule)"
                   [matTooltip]="registrationModule.Tooltip"></icon-button>
      <icon-button *ngIf="registrationModule?.ShowResetButton" icon="reset_arrow" (click)="handleResetSession()"
                   [matTooltip]="registrationModule.ResetButtonTooltip"></icon-button>
    </div>
  </div>
</nav>