<div class="pin-container blocking-overlay-backdrop" *ngIf="isShowing" [class.stick-right]="stickRight" [class.single-box]="useSingleBox"
     @enterLeaveFadeAnimation>
    <div class="pin-pad" [class.wrong-pin]="wrongPin">
        <div class="pin-pad-header">
            <div class="pin-pad-title">
                {{salesConsultantTitle}} Log In
                <icon name="x" class="close-icon" (click)="handleClose($event)"></icon>
            </div>
            <div class="pin-pad-label">Enter PIN</div>
            <input type="password" id="pin" [(ngModel)]="pin" [readonly]="!acceptKeyboard" [class.wrong-pin]="wrongPin" (keyup.enter)="handleSubmit()"
                   [mlAutofocus]="acceptKeyboard" autocomplete="off" />
        </div>
        <div class="pin-pad-numbers">
            <div class="pin-row">
                <div class="pin-cell" (click)="handleButtonPress('7')"><button><span>7</span></button></div>
                <div class="pin-cell" (click)="handleButtonPress('8')"><button><span>8</span></button></div>
                <div class="pin-cell" (click)="handleButtonPress('9')"><button><span>9</span></button></div>
            </div>
            <div class="pin-row">
                <div class="pin-cell" (click)="handleButtonPress('4')"><button><span>4</span></button></div>
                <div class="pin-cell" (click)="handleButtonPress('5')"><button><span>5</span></button></div>
                <div class="pin-cell" (click)="handleButtonPress('6')"><button><span>6</span></button></div>
            </div>
            <div class="pin-row">
                <div class="pin-cell" (click)="handleButtonPress('1')"><button><span>1</span></button></div>
                <div class="pin-cell" (click)="handleButtonPress('2')"><button><span>2</span></button></div>
                <div class="pin-cell" (click)="handleButtonPress('3')"><button><span>3</span></button></div>
            </div>
            <div class="pin-row">
                <div class="pin-cell special" (click)="handleDelete()">
                    <button><span>
                            <icon name="x"></icon>
                        </span></button></div>
                <div class="pin-cell" (click)="handleButtonPress('0')"><button><span>0</span></button></div>
                <div class="pin-cell special" (click)="handleSubmit()"><button><span>Ok</span></button></div>
            </div>
        </div>
    </div>
</div>