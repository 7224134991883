import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable()
export class ServiceWorkerUpdate {
  beforeInstallDeferred: any;
  isAppInstalled = false;

  constructor(private appRef: ApplicationRef, private updates: SwUpdate) {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.beforeInstallDeferred = e;
    });

    window.addEventListener('appinstalled', evt => {
      console.log('app installed');
      this.isAppInstalled = true;
    });
  }

  initialize() {
    if (this.updates.isEnabled) {
      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      // Keep in mind ngsw-worker.js will always check for updates on page initialize and then use it on next refresh
      const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
      const everyTenMinutes$ = interval(10 * 60 * 1000);
      const everyTenMinutesOnceAppIsStable$ = concat(appIsStable$, everyTenMinutes$);
      everyTenMinutesOnceAppIsStable$.subscribe(() => this.updates.checkForUpdate());
    } else {
      console.log('Service worker is currently disabled');
    }
  }

  getInstallPrompt() {
    return this.beforeInstallDeferred;
  }

  appInstalled(): boolean {
    return this.isAppInstalled;
  }
}
