import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { ErrorReasons } from '../error/error-reasons.enum';
import { AppModeTypes } from '../utility/helpers/app-mode.enum';
import { PushToGoogleTagManager } from '../utility/helpers/google-tag-manager';
import { FavoritesSession } from '../utility/models/favorites-session';
import { OfflineEvent } from '../utility/offline/offline.event';
import { CommunityService } from '../utility/services/community.service';
import { FavoritesService } from '../utility/services/favorites.service';
import { HomeBuyerService } from '../utility/services/home-buyer/home-buyer.service';
import { IdleService } from '../utility/services/idle.service';
import { NavigationService, PageTitle } from '../utility/services/navigation.service';
import { OfflineService } from '../utility/services/offline.service';
import { SettingsService } from '../utility/services/settings.service';

import { ICommunity, IHomeBuyer } from '@ml/common';

@Component({
  selector: 'myscp-root',
  template: `
    <router-outlet></router-outlet>
    <quick-nav></quick-nav>
    <sales-pin-pad></sales-pin-pad>
    <home-buyer-registration></home-buyer-registration>
    <fullscreen-gallery></fullscreen-gallery>
    <toast></toast>
    <positionable-popup></positionable-popup>
  `,
  styleUrls: ['./myscp-root.component.scss']
})
export class MyScpRootComponent implements OnInit, OnDestroy {
  @HostBinding('class.shift-up-for-nav') usePermanentBottomNav = false;
  subs: Subscription[] = [];

  constructor(
    private favorites: FavoritesService,
    private homeBuyerService: HomeBuyerService,
    private communityService: CommunityService,
    private idleService: IdleService,
    private navigator: NavigationService,
    private settingsService: SettingsService,
    private offlineService: OfflineService
  ) {}

  ngOnInit() {
    this.navigator.detectApplicationMode();

    this.usePermanentBottomNav =
      this.navigator.AllowAppNavigation && !!this.settingsService.get('UsePermanentBottomNav');

    if (!this.settingsService.get('ShowFloorPlanPricing'))
      this.communityService.wipeOutFloorPlanPricing();

    const community = this.communityService.current;
    if (this.navigator.IsInStandaloneMode) this.initializeStandaloneMode(community);

    this.favorites.initializeFromSessionStorage();
    this.updateBrowserTabTitle(this.communityService.getDisplayName());

    try {
      this.homeBuyerService.initializeFromLocalStorage(community);

      this.subs.push(
        this.homeBuyerService.current$.subscribe((homeBuyer: IHomeBuyer) => {
          if (homeBuyer) {
            const favoritesSession = homeBuyer.FavoritesSessions.find(
              x => x.CommunityId === community.CommunityId
            );

            if (favoritesSession)
              this.favorites.mergeFavoritesWithIncomingHomeBuyerFavorites(favoritesSession);
          }
        })
      );

      this.initIdleTimer();
    } catch {
      console.error('HomeBuyer email found in sessionStorage but unable to retrieve');

      this.favorites.initializeFromSessionStorage();
    }

    PushToGoogleTagManager({
      eventName: 'CommunityLoad',
      eventValue: community.CommunityId.toString(),
      extraData: {
        CommunityId: community.CommunityId,
        CommunityName: community.Name,
        DivisionId: community.ProjectId,
        DivisionName: community.ProjectName,
        ClientId: community.ClientId,
        ClientName: community.ClientName,
        IsDownloadedForOfflineUse: this.offlineService.isDownloaded(community.CommunityId)
      }
    });
  }

  ngOnDestroy() {
    this.subs.forEach(x => x.unsubscribe());
  }

  private updateBrowserTabTitle(communityName: string) {
    const title = document.querySelector('head title');
    if (title) title.innerHTML = `MySCP - ${communityName}`;
  }

  private initIdleTimer() {
    const timeoutMins = +this.settingsService.get('IdleTimeout');
    if (timeoutMins > 0 && this.navigator.AllowAppNavigation) {
      const timeoutSecs = timeoutMins * 60;
      this.subs.push(
        this.idleService.startWatching(timeoutSecs).subscribe((isTimedOut: boolean) => {
          if (isTimedOut) {
            this.homeBuyerService.hasRegistrationAutoPopped = false;

            const homebuyer = this.homeBuyerService.current;
            if (homebuyer) {
              this.favorites.logoutAndClearSession();
            } else {
              this.favorites.current = new FavoritesSession();
            }

            this.navigator.go({ PageTitle: PageTitle.Homepage });

            if (this.communityService.current.HasOfflineAccess) this.autoUpdateOffline();
            else window.setTimeout(() => window.location.reload(), 1000);
          }
        })
      );
    }
  }

  private autoUpdateOffline() {
    if (this.offlineService.isDownloaded(this.communityService.current.CommunityId)) {
      PushToGoogleTagManager({
        eventName: 'OfflineAutoUpdate',
        eventValue: this.communityService.current.CommunityId.toString(),
        extraData: {
          CommunityId: this.communityService.current.CommunityId,
          CommunityName: this.communityService.current.Name
        }
      });
      this.offlineService.download(this.communityService.current).subscribe(
        (event: OfflineEvent) => {
          console.log('Updating: ' + event.Progress + '%');
          if (event.Progress >= 100) window.location.reload();
        },
        () => window.location.reload()
      );
    } else {
      this.offlineService.deleteCommunity(this.communityService.current.CommunityId);
      window.setTimeout(() => window.location.reload(), 1000);
    }
  }

  private initializeStandaloneMode(community: ICommunity) {
    if (
      this.navigator.AppMode === AppModeTypes.StandaloneISM &&
      !community.ProductPermissions.HasMyScpStandaloneISMEnabled
    ) {
      this.navigator.handleErrorRedirect(ErrorReasons.ProductNotEnabledForCommunity);
      return;
    }
    if (
      this.navigator.AppMode === AppModeTypes.StandaloneAreaMap &&
      !community.ProductPermissions.HasMyScpStandaloneAreaMapEnabled
    ) {
      this.navigator.handleErrorRedirect(ErrorReasons.ProductNotEnabledForCommunity);
      return;
    }
    if (
      this.navigator.AppMode === AppModeTypes.StandaloneVisualizer &&
      !community.ProductPermissions.HasMyScpStandaloneVisualizerEnabled
    ) {
      this.navigator.handleErrorRedirect(ErrorReasons.ProductNotEnabledForCommunity);
      return;
    }
    if (this.navigator.AppMode !== AppModeTypes.StandaloneVisualizer)
      this.favorites.turnOffFavoriting();
  }
}
