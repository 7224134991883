import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { PushToGoogleTagManager } from '../../utility/helpers/google-tag-manager';
import { OverlayService, OverlayTypes } from '../../utility/services/overlay.service';
import { SalesRepresentativeService } from '../../utility/services/sales-representative.service';
import { SettingsService } from '../../utility/services/settings.service';
import { enterLeaveFadeAnimation } from '../animations';
import { ToastService } from '../toast/toast.service';

@Component({
  selector: 'sales-pin-pad',
  templateUrl: './sales-pin-pad.component.html',
  styleUrls: ['./sales-pin-pad.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterLeaveFadeAnimation()]
})
export class SalesPinPadComponent implements OnInit, OnDestroy {
  isShowing = false;
  stickRight = false;
  pin = '';
  acceptKeyboard = false;
  wrongPin = false;
  sub: Subscription;
  useSingleBox = false;
  salesConsultantTitle: string;

  constructor(
    private cdr: ChangeDetectorRef,
    private overlay: OverlayService,
    private toaster: ToastService,
    private salesRepService: SalesRepresentativeService,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    this.acceptKeyboard = !environment.production;

    this.salesConsultantTitle = this.settingsService.get('SalesConsultantTitle');
    this.useSingleBox = this.settingsService.get('PinPadStyle') === 'Single box';

    this.sub = this.overlay.state$
      .pipe(filter(state => state.targetedComponent === OverlayTypes.SalesPinPad))
      .subscribe(state => {
        this.isShowing = state.isShowing;
        this.stickRight = state.stickRight;
        if (!this.isShowing) {
          this.pin = '';
          this.wrongPin = false;
        }
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  handleButtonPress(button: string) {
    this.wrongPin = false;
    this.pin += button;
  }

  handleDelete() {
    this.pin = this.pin.slice(0, -1);
  }

  handleSubmit() {
    const currentSalesRep = this.salesRepService.login(this.pin);
    if (currentSalesRep) {
      this.handleClose();
      this.toaster.showInfo(
        `Welcome back, ${currentSalesRep.FirstName} ${currentSalesRep.LastName}!`
      );
      PushToGoogleTagManager({
        eventName: 'PinLogin',
        eventValue: currentSalesRep.PIN,
        extraData: {
          SalesRepresentativeId: currentSalesRep.SalesRepresentativeId,
          SalesRepresentativeName: `${currentSalesRep.FirstName} ${currentSalesRep.LastName}`,
          SalesRepresentativeEmail: currentSalesRep.Email,
          SalesRepresentativePIN: currentSalesRep.PIN
        }
      });
    } else {
      this.wrongPin = true;
    }
    this.pin = '';
  }

  handleClose(evt?: Event) {
    if (evt) evt.stopPropagation();

    this.overlay.hide(OverlayTypes.SalesPinPad);
  }
}
