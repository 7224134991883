import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { filter } from 'rxjs/operators';

import { AutoUnsubscriber } from '../../utility/helpers/mixins';
import { RegexPatterns } from '../../utility/helpers/regex';
import {
  IClientRegistration,
  IFormItem,
  IRegistrationForm,
  QuestionType
} from '../../utility/interfaces/registration-form';
import { HomeBuyerVM } from '../../utility/models/home-buyer';
import { ApiService } from '../../utility/services/api.service';
import { CommunityService } from '../../utility/services/community.service';
import { FavoritesService } from '../../utility/services/favorites.service';
import { HomeBuyerService } from '../../utility/services/home-buyer/home-buyer.service';
import { OverlayService, OverlayTypes } from '../../utility/services/overlay.service';
import { SalesRepresentativeService } from '../../utility/services/sales-representative.service';
import { SettingsService } from '../../utility/services/settings.service';
import { ThemeService } from '../../utility/services/theme.service';
import { enterLeaveFadeAnimation } from '../animations';
import { ToastService } from '../toast/toast.service';

import { CanadianProvinceList, IHomeBuyer, UsStateList } from '@ml/common';

@Component({
  selector: 'home-buyer-registration',
  templateUrl: './home-buyer-registration.component.html',
  styleUrls: ['./home-buyer-registration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [enterLeaveFadeAnimation()]
})
export class HomeBuyerRegistrationComponent extends AutoUnsubscriber implements OnInit {
  isShowing = false;
  stickRight = false;
  communityLogo: string;
  message: string;
  isNewHomeBuyer: boolean;
  homeBuyer: HomeBuyerVM;
  salesRepId: string;
  regexPatterns = RegexPatterns;
  disclaimer: string;
  useSingleBox = false;
  customClientRegistration: IClientRegistration;
  allCustomRequiredQuestionsHaveValue = true;
  questionModel = new Object();
  requireExisting: IFormItem[];
  questionType = QuestionType;
  useCheckboxWithDisclaimer = false;
  disclaimerConsent = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private overlay: OverlayService,
    private homeBuyerService: HomeBuyerService,
    private toaster: ToastService,
    private theme: ThemeService,
    private communityService: CommunityService,
    private salesRepService: SalesRepresentativeService,
    private settingsService: SettingsService,
    private favorites: FavoritesService,
    private apiService: ApiService
  ) {
    super();
  }

  ngOnInit() {
    this.communityLogo = this.theme.getCommunityLogo();
    this.disclaimer = this.settingsService
      .get('HomeBuyerDisclaimerText')
      ?.replace('{{currentYear}}', new Date().getFullYear());
    this.useSingleBox = this.settingsService.get('HomeBuyerRegistrationStyle') === 'Single box';

    this.addSub(
      this.overlay.state$
        .pipe(filter(state => state.targetedComponent === OverlayTypes.HomeBuyerRegistration))
        .subscribe(state => {
          this.isShowing = state.isShowing;
          this.stickRight = state.stickRight;
          if (this.isShowing) this.initializeWelcomeScreen();
          this.cdr.detectChanges();
        })
    );

    this.addSub(
      this.salesRepService.current$.subscribe(salesRep => {
        this.salesRepId = salesRep ? salesRep.SalesRepresentativeId : null;
      })
    );

    this.apiService.getWithNoAuth('/assets/config/form_config.json').then(questions => {
      const regForm: IRegistrationForm = JSON.parse(questions);

      this.customClientRegistration = regForm.Clients.find(
        x =>
          x.ClientId === this.communityService.current.ClientId ||
          x.ClientIds?.includes(this.communityService.current.ClientId)
      );

      this.requireExisting = this.customClientRegistration?.Existing || regForm.Default;
      this.useCheckboxWithDisclaimer = this.customClientRegistration?.UseCheckboxWithDisclaimer;

      if (!this.customClientRegistration) return;

      if (this.customClientRegistration.Questions.some(x => x.Required && !x.DefaultValue))
        this.allCustomRequiredQuestionsHaveValue = false;

      this.customClientRegistration.Questions?.forEach(x => {
        if (x.DefaultValue) {
          this.questionModel[x.Name] = x.DefaultValue;
        } else {
          this.questionModel[x.Name] = '';
        }
      });
    });
  }

  private initializeWelcomeScreen() {
    this.isNewHomeBuyer = false;
    this.message = 'Enter your email to continue.';
    this.homeBuyer = new HomeBuyerVM();
    this.homeBuyer.ClientId = this.communityService.current.ClientId;
    this.homeBuyer.SalesRepresentativeId = this.salesRepId;
  }

  handleClose(evt?: Event) {
    if (evt) evt.stopPropagation();

    this.overlay.hide(OverlayTypes.HomeBuyerRegistration);
  }

  handleCheckEmailAddress(form: NgForm) {
    if (form.invalid) return;

    this.toaster.showLoading();
    this.homeBuyerService.get(this.homeBuyer.mapToEntity()).subscribe(
      (homeBuyer: IHomeBuyer) => {
        this.homeBuyerService.current = homeBuyer;
        this.handleClose();
        this.toaster.showInfo(`Welcome back, ${new HomeBuyerVM(homeBuyer).FullName}!`);
      },
      (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.message = 'Welcome! Please complete your registration to continue.';
          this.isNewHomeBuyer = true;
          this.toaster.hideLoading();
          this.cdr.detectChanges();
        } else {
          this.toaster.showStickyError('Error looking up home buyer by email');
          console.error(`Error looking up home buyer by email: ${error}`);
        }
      }
    );
  }

  onEmailChange(email: string) {
    this.homeBuyer.Email = email;
    this.cdr.detectChanges();
  }

  onFirstNameChange(val: string) {
    this.homeBuyer.FirstName = val;
    this.cdr.detectChanges();
  }

  onLastNameChange(val: string) {
    this.homeBuyer.LastName = val;
    this.cdr.detectChanges();
  }

  onTelephoneChange(val: string) {
    //trim everything but numbers from the input.
    const trimmedInput = val.replace(/\D+/g, '');
    // TODO - regex to add parens and hyghphen to phone number while typing. It's close but not 100% yet
    // const addParens = trimmedInput.replace(/^(\d{3})?(\d{3})/g, '($2) $1-');
    this.homeBuyer.PhoneNumber = trimmedInput;
    this.cdr.detectChanges();
  }

  onQuestionChange(val: string, name: string) {
    this.questionModel[name] = val;

    if (name === 'Country') {
      const stateSelect = this.customClientRegistration.Questions.find(x => x.Name === 'State');
      if (stateSelect) stateSelect.Options = this.getCountrySpecificStates(val);
    }

    if (this.customClientRegistration) {
      this.allCustomRequiredQuestionsHaveValue = this.customClientRegistration.Questions.filter(
        x => x.Required
      ).every(x => !!this.questionModel[x.Name]);
    }

    this.cdr.detectChanges();
  }

  getCountrySpecificStates(country: string) {
    switch (country) {
      case 'Canada':
        return CanadianProvinceList.map(x => x.Abreviation);
      case 'USA':
      default:
        return UsStateList.map(x => x.Abreviation);
    }
  }

  handleCheckboxes(evt: any, name: string) {
    if (this.questionModel[name] === evt.target.value) {
      this.questionModel[name] = '';
    } else {
      this.questionModel[name] = evt.target.value;
    }
  }

  async handleRegister(form: NgForm) {
    if (form.invalid) return;

    this.toaster.showLoading();
    try {
      if (!this.homeBuyer.CustomData) this.homeBuyer.CustomData = {};
      this.homeBuyer.CustomData.DisclaimerConsent = this.disclaimerConsent;

      const savedHomeBuyer = await this.homeBuyerService.create(
        this.homeBuyer,
        this.favorites.current,
        this.questionModel
      );
      this.homeBuyerService.current = savedHomeBuyer;
      this.handleClose();
      // immediately save FavoritesSession to API for help with data reports
      await this.favorites.save(this.favorites.current);
      this.toaster.showInfo(`Welcome ${savedHomeBuyer.FullName}!`);

      // reset form and optional fields
      form.resetForm();
      this.homeBuyer = new HomeBuyerVM();
      this.questionModel = new Object();

      this.customClientRegistration?.Questions?.forEach(x => {
        if (x.DefaultValue) {
          this.questionModel[x.Name] = x.DefaultValue;
        } else {
          this.questionModel[x.Name] = '';
        }
        if (x.Type === QuestionType.Select) {
          this.questionModel[x.Name] = '';
        }
      });
    } catch (err) {
      this.toaster.showStickyError('Error creating new home buyer');
      console.error(`Error creating new home buyer: ${err}`);
    }
  }
}
