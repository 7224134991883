<div class="home-buyer-registration-container blocking-overlay-backdrop" *ngIf="isShowing" [class.stick-right]="stickRight" @enterLeaveFadeAnimation>
    <div class="home-buyer-registration">
        <icon name="x" class="close-icon" (click)="handleClose($event)"></icon>
        <div class="home-buyer-registration-header" *ngIf="communityLogo && !useSingleBox">
            <img [src]="communityLogo" />
        </div>
        <div class="home-buyer-registration-form">
            <div class="home-buyer-registration-form-header">Getting Started</div>
            <div class="home-buyer-registration-form-subheader">{{message}}</div>

            <ng-container *ngIf="!isNewHomeBuyer">
                <form #emailForm="ngForm" (ngSubmit)="handleCheckEmailAddress(emailForm)" class="home-buyer-registration-form-inputs"
                      autocomplete="off">
                    <div class="row"><input type="text" placeholder="Email*" name="Email" [ngModel]="homeBuyer.Email"
                               (ngModelChange)='onEmailChange($event)' required [pattern]="regexPatterns.EmailAddress" [mlAutofocus]="true"
                               autocomplete="off" />
                    </div>
                    <div class="row">
                        <icon-button text="Continue" [showArrow]="true" class="priority" [disabled]="emailForm.pristine || emailForm.invalid">
                        </icon-button>
                    </div>
                </form>
            </ng-container>

            <ng-container *ngIf="isNewHomeBuyer">
                <form #registrationForm="ngForm" (ngSubmit)="handleRegister(registrationForm)" class="home-buyer-registration-form-inputs"
                      autocomplete="off">
                    <div class="row name-line">
                        <input type="text" [placeholder]="requireExisting[0].Name" name="FirstName" [ngModel]="homeBuyer.FirstName"
                               (ngModelChange)="onFirstNameChange($event)" [required]="requireExisting[0].Required" [mlAutofocus]="true"
                               autocomplete="off" />
                        <input type="text" [placeholder]="requireExisting[1].Name" name="LastName" [ngModel]="homeBuyer.LastName"
                               (ngModelChange)="onLastNameChange($event)" [required]="requireExisting[1].Required" autocomplete="off" />
                    </div>
                    <div *ngIf="requireExisting[2]?.Name" class="row">
                        <input maxlength="13" type="tel" [placeholder]="requireExisting[2].Name" name="Telephone" [ngModel]="homeBuyer.PhoneNumber"
                               (ngModelChange)="onTelephoneChange($event)" [required]="requireExisting[2].Required" autocomplete="off" />
                    </div>

                    <ng-container *ngIf="customClientRegistration">
                        <div *ngFor="let item of customClientRegistration.Questions" [class.row]="!item.Classnames?.length"
                             [ngClass]="item.Classnames">

                            <input *ngIf="item.Type === questionType.Text" [placeholder]="item.Question" type="text" [required]="item.Required"
                                   [ngModel]="questionModel[item.Name]" (ngModelChange)="onQuestionChange($event, item.Name)" [name]="item.Name" />

                            <mat-select *ngIf="item.Type === questionType.Select" [(value)]="questionModel[item.Name]"
                                        (selectionChange)="onQuestionChange($event.value, item.Name)" [required]="item.Required">
                                <mat-option disabled value="">{{item.Question}}</mat-option>
                                <mat-option *ngFor="let option of item.Options" [value]="option">{{option}}</mat-option>
                            </mat-select>

                            <div *ngIf="item.Type === questionType.Checkbox" class="checkbox-field">
                                <label>
                                    {{item.Question}}
                                </label>
                                <div class="checkbox-container">
                                    Yes<input type="checkbox" value="Yes" [checked]="questionModel[item.Name] === 'Yes'"
                                           (change)="handleCheckboxes($event, item.Name)" />
                                    No<input type="checkbox" value="No" [checked]="questionModel[item.Name] === 'No'"
                                           (change)="handleCheckboxes($event, item.Name)" />
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <div class="row">
                        <icon-button text="Register" [showArrow]="true" class="priority"
                                     [disabled]="registrationForm.pristine || registrationForm.invalid || !allCustomRequiredQuestionsHaveValue"></icon-button>
                    </div>
                </form>
            </ng-container>

            <div *ngIf="disclaimer && useSingleBox" class="home-buyer-registration-disclaimer">
                <input *ngIf="useCheckboxWithDisclaimer" type="checkbox" [(ngModel)]="disclaimerConsent" />
                {{disclaimer}}
            </div>
        </div>
        <div *ngIf="disclaimer && !useSingleBox" class="home-buyer-registration-disclaimer">
            <input *ngIf="useCheckboxWithDisclaimer" type="checkbox" [(ngModel)]="disclaimerConsent" />
            {{disclaimer}}
        </div>
    </div>
</div>